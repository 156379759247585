import React from 'react'
import { Link } from 'react-router-dom'
import { Carousel } from 'antd';
import {RightOutlined,LeftOutlined} from '@ant-design/icons';
import styles from '../index.module.scss'
import { changeImg } from '../pub'
const title_type_1 = (font)=>{
  return(
      <div className={styles.title_type_1}>
          <span>{font}</span>
          <i className={styles.i_large2}></i>
          <i className={styles.i_small2}></i>  
      </div>
  )
}
class TopSwiper extends React.Component {
  checkTurn=(val)=>{
    val=='L'?this.topCarousel.prev():this.topCarousel.next()
  }
  
  render() {
    const { lmtjData } = this.props
    return (
        <div className={styles.topSwiper}>
            <div className='swiperBox'>
              <div className='turnBtn turnBtn-l' onClick={()=>this.checkTurn('L')}><LeftOutlined /></div>
              <Carousel autoplay ref={c=>this.topCarousel=c}>
              {lmtjData.articles.slice(0,3).map(el=>{
                return (
                <div className='topSwiper-slide' key={el.id}>
                  {/* <Link to={`/curatorSessionCon?articleId=${el.id}&termId=${el.term_id}`} key={el.id}> */}
                    <img src={changeImg(el.smeta.photo[0].url)} alt="" />
                    {/* <p className='curatorTopSwText'>{el.post_title}</p> */}
                  {/* </Link> */}
                </div>
                )
              })
              }
              </Carousel>
              <div className='turnBtn turnBtn-R' onClick={()=>this.checkTurn('R')}><RightOutlined/></div>
            </div>

            <div className='topSwiperMsgBox'>
              {
                title_type_1('北京国际科学传播交流周')
              }
              <p className='topSwiperMsg-msg'>
              北京国际科学传播交流周发起于2019年，旨在促进国际科学传播经验互鉴与资源共享，凝聚全球力量共同提升公众科学素质。 2023年主题为数字素养·教育未来，设置开幕式，主旨报告，北京中外科技馆馆长对话会、北京国际城市科学节联盟年会暨国际科学节圆桌会议等四个专题平行论坛，国际科普作品展览展示等多项特色活动。
              </p>
            </div>
            
        </div>
    )
  }
}
export default TopSwiper

import React from 'react'
import styles from '../list.module.scss'
import { ClockCircleOutlined, SwapRightOutlined } from '@ant-design/icons';
import { changeImg } from '../pub';
import { withRouter } from 'react-router-dom';
class JchdItem extends React.Component {
    turn(id, termId, posts_link) {
        if(posts_link) {
            window.open(posts_link)
        } else {
            this.props.history.push(`/curatorSession2024Con?articleId=${id}&termId=${termId}`)

        }
    }
    render() {
        const { post_title, post_excerpt, post_date, smeta, dindex, termId, id, posts_link } = this.props
        return (
            <>
                <div className={styles.jchdItem} style={{ marginRight: (dindex + 1) % 2 != 0 ? '22px' : '0px' }} onClick={() => this.turn(id, termId, posts_link)}>
                    <img src={smeta.photo ? changeImg(smeta.photo[0].url) : `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/zwtp.jpg`} alt="" />
                    <div className='mooter'>
                        <p className='title'>{post_title}</p>
                        <div className='btn'>
                            了解更多
                        </div>
                    </div>
                </div>
                <div className={styles.jchdItemYd} style={{ marginRight: (dindex + 1) % 2 != 0 ? '.18rem' : '0px' }} onClick={() => this.turn(id, termId, posts_link)}>
                    <img src={smeta.photo ? changeImg(smeta.photo[0].url) : `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/zwtp.jpg`} alt="" />
                    <div className='mooter'>
                        <p className='title'>{post_title}</p>
                        <div className='btn'>
                            了解更多
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(JchdItem)

/*
 * @Author: your name
 * @Date: 2020-03-19 10:54:01
 * @LastEditTime: 2020-09-23 14:41:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\index.js
 */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'

import ReactDOM from 'react-dom'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import './reset.css'
import Header from './pages/header'
import Footer from './pages/footer'
import Home from './pages/home'
import Library from './pages/library'
import Weifuwu from './pages/weifuwu'
import Service from './pages/service'
import Questions from './pages/questions'
import Exhibition from './pages/exhibition/index.jsx'
import Education from './pages/education'
import Cinema from './pages/cinema'
import Communication from './pages/communication'
import Train from './pages/train'
import NewTrain from './pages/newtrain'
import TalentPlan from './pages/talentPlan'
import PartyMasses from './pages/PartyMasses'
import Zxfk from './pages/zxfk'
import Zyz from './pages/zyz'
import SearchWrap from './pages/search'
import Article from './pages/article'
import List from './pages/list'
import List2 from './pages/list/list2'
import List3 from './pages/list/list3'
import List4 from './pages/list/list4'
import ListTab from './pages/list/list_tab'
import ListTab2 from './pages/list/list_tab2'
import kxjtList from './pages/list/kxjtlist'
import NyrzZqyh from './pages/nyrzZqyh'
import NyrzZqyhList from './pages/nyrzZqyh/list.jsx'
import NyrzZqyhContent from './pages/nyrzZqyh/content'
import CuratorSessionAll from './pages/curatorSessionAll'
import CuratorSession from './pages/curatorSession'
import CuratorSessionList from './pages/curatorSession/list'
import CuratorSessionCon from './pages/curatorSession/content'
import CuratorSession2024 from './pages/curatorSession2024'
import CuratorSession2024List from './pages/curatorSession2024/list'
import CuratorSession2024Con from './pages/curatorSession2024/content'
import Union from './pages/union'
import UnionList from './pages/union/list'
import UnionCon from './pages/union/content'
import UnionCgList from './pages/union/cglist'
import UnionCgCon from './pages/union/cgContent'
import UnionNewsList from './pages/union/newslist'
import Mkjkx from './pages/mkjkx'
import Xydgg from './pages/xydgg'
import Xsdll from './pages/xsdll'
import { Provider } from 'react-redux'
import Preview from './pages/preview'
import store from './store'
import FooterFixed from './pages/footerFixed'
import { Kxzx, KxzxContent } from './style.js'
import request from './utils/request'
React.$request=request
// import axios from 'axios'
// axios.interceptors.request.use(config => {
//   let url='/administrator/'
//   let ourl = config.url.split('?')[1]
//   let porams=[]
//   porams=ourl.split('&')
//   porams.forEach(el=>{
//     let key=el.split('=')[0]
//     if(['g','m','a'].includes(key)){
//       url+=el.split('=')[1]+'/'
//     }
//   })
//   config.url=url+'?'+ourl
//   return config
// }, error => {
//   Promise.reject(error)
// })

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeaderFooter: true, // 默认显示 header 和 footer
    };
  }
  componentDidMount() {
    this.updateShowHeaderFooter();
    // window.addEventListener('hashchange', this.updateShowHeaderFooter);
  }

  // componentWillUnmount() {
  //   window.removeEventListener('hashchange', this.updateShowHeaderFooter);
  // }

  updateShowHeaderFooter = () => {
    const { hash } = window.location;
    // 根据 hash 判断是否显示 header 和 footer
    // 假设当访问 '/weifuwu' 开头的页面时不显示 header 和 footer
    // const shouldShow = !hash.startsWith('#/weifuwu');
    // this.setState({ showHeaderFooter: shouldShow });
  };
  render() {
    const { showHeaderFooter } = this.state;
    return (
      // <React.StrictMode>
        <Kxzx>
          <Provider store={store}>
            <HashRouter>
              {showHeaderFooter && <Header />}  {/* 根据状态决定是否渲染 Header */}
              <KxzxContent>
                <Switch>
                  <Route exact path="/" component={Home} /> 
                  <Route path="/home" component={Home} />
                  <Route path="/library" render={() => <Library />} />
                  <Route path="/weifuwu" component={Weifuwu} />
                  <Route path="/service" render={() => <Service />} />
                  <Route path="/questions" render={() => <Questions />} />
                  <Route path="/exhibition" render={() => <Exhibition />} />
                  <Route path="/education" render={() => <Education />} />
                  <Route path="/cinema" render={() => <Cinema />} />
                  <Route path="/communication" render={() => <Communication />}/>
                  <Route path="/train" render={() => <Train />} />
                  <Route path="/newtrain" render={() => <NewTrain />} />
                  <Route path="/talentPlan" render={() => <TalentPlan />} />
                  <Route path="/partyMasses" render={() => <PartyMasses />} />
                  <Route path="/curatorSessionAll" render={()=><CuratorSessionAll/>}/>
                  <Route path="/curatorSession" render={()=><CuratorSession/>}/>
                  <Route path="/curatorSessionList" render={()=><CuratorSessionList/>}/>
                  <Route path="/curatorSessionCon" render={()=><CuratorSessionCon/>}/>
                  <Route path="/curatorSession2024" render={()=><CuratorSession2024/>}/>
                  <Route path="/curatorSession2024List" render={()=><CuratorSession2024List/>}/>
                  <Route path="/curatorSession2024Con" render={()=><CuratorSession2024Con/>}/>
                  <Route path="/union" render={()=><Union/>}/>
                  <Route path="/unionList" render={()=><UnionList/>}/>
                  <Route path="/unionCon" render={()=><UnionCon/>}/>
                  <Route path="/unionCgList" render={()=><UnionCgList/>}/>
                  <Route path="/unionCgCon" render={()=><UnionCgCon/>}/>
                  <Route path="/unionNewsList" render={()=><UnionNewsList/>}/>
                  <Route path="/nyrzZqyh" render={()=><NyrzZqyh/>}/>
                  <Route path="/nyrzZqyhList" render={()=><NyrzZqyhList/>}/>
                  <Route path="/NyrzZqyhContent" render={()=><NyrzZqyhContent/>}/>
                  <Route path="/zxfk" component={Zxfk} />
                  <Route path="/zyz" component={Zyz} />
                  <Route path="/article/:id" component={Article} />
                  <Route path="/search/:id" component={SearchWrap} />
                  <Route path="/list/:id" component={List} />
                  <Route path="/list2/:id" component={List2} />
                  <Route path="/list3/:id" component={List3} />
                  <Route path="/list4/:id" component={List4} />
                  <Route path="/kxjtlist/:id" component={kxjtList} />
                  <Route path="/listtab/:id" component={ListTab} />
                  <Route path="/listtab2/:id" component={ListTab2} />
                  <Route path="/mkjkx/" component={Mkjkx} />
                  <Route path="/xydgg/" component={Xydgg} />
                  <Route path="/xsdll/" component={Xsdll} />
                  <Route path="/preview/:id" component={Preview} />
                </Switch>
              </KxzxContent>
              {showHeaderFooter && <Footer />} {/* 根据状态条件渲染 Footer */}
              {showHeaderFooter && <FooterFixed />} {/* 根据状态条件渲染 FooterFixed */}
            </HashRouter>
          </Provider>
        </Kxzx>
      // </React.StrictMode>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

import React from 'react'
import styles from '../list.module.scss'
import { ClockCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { changeImg } from '../pub';
export default class GjjlItem extends React.Component {
  render() {
    const { post_title, post_excerpt, post_date, smeta, id, termId } = this.props
    return (
      <Link to={`/curatorSession2024Con?articleId=${id}&termId=${termId}`}>
        <div className={styles.gjjlItem}>
          <img src={smeta.photo ? changeImg(smeta.photo[0].url) : `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/zwtp.jpg`} alt="" />
          <div className='conBox'>
            <p className="title">{post_title}</p>
            <p className="msg">{post_excerpt}</p>
            <div className='timeBox'>
              <p><ClockCircleOutlined />&nbsp;&nbsp;{post_date.split(' ')[0]}</p>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}


import React from 'react';

import {NavLink,Switch,Route,Redirect} from 'react-router-dom'

import {CuratorSessionAllWrap} from './style'
import NavSection from 'common/navSection'
import {Skeleton} from 'antd'
import Crumbs from './list-components/crumbs'
import styles from './index.module.scss'

class CuratorSessionAll extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            headerList:{}
        }
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            <CuratorSessionAllWrap>
                <div className={styles.curatorSession}>
                    <Crumbs currentPos={'index'} />
                </div>
                <div className="banner-img">
                    <a href='/#/curatorSession2024' className="banner-img-link">  
                        <img src={`/img/communication/kjggzdhh-banner.jpg`} className="banner-img-item" />  
                    </a>
                    <a href='/#/curatorSession' className="banner-img-link">  
                        <img src={`/img/communication/kjggzdhh-banner-2023.jpg`} className="banner-img-item" />  
                    </a>   
                </div>
            </CuratorSessionAllWrap>
        )
    }
}
export default CuratorSessionAll
import React from 'react';
import {NavLink} from 'react-router-dom'
import { FooterFixedWrap } from './style.js'

class FooterFixed extends React.Component {
    render() {
        return (
            <FooterFixedWrap>

                <a className='footer_link' href='http://ticket.bjsc.net.cn/'>
                    <i><img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/homeicon_06.png`} alt=""/></i>预订入口
                </a>
                <a className='footer_link' href="http://bjsc.net.cn/en/index.html">
                    <i><img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/homeicon_03.png`} alt=""/></i>
                    英文网站
                </a>
            </FooterFixedWrap>
        )
    }
}
export default FooterFixed
export function changeImg(val){
    if (!val) {
        return ''
    }
    if(val.indexOf('www.bjsc.net.cn')!=-1){
        return val
    }else{
        return `${process.env.REACT_APP_IMGBASE}` + val
    }
}

export function getColumKey(){
    return {
        1064:'新闻中心',
        1065:'新闻中心',
        1066:'新闻中心',
        1067:'新闻中心',
        1068:'新闻中心',
        1069:'官方视频',
        1070:'发言嘉宾',
        1071:'成果集',
        1072:'媒体报道',
        1073:'精彩瞬间',
        1074:'机构风采',
        1075:'栏目推荐'      
    }
}
    import React from 'react'
import styles from '../cglist.module.scss'
import GjjlItem from './gjjlItem'
import { Pagination } from 'antd';
import JcspItem from './jcspItem';
import JchdItem from './jchdItem';
import qs from 'query-string'
import { withRouter } from 'react-router-dom';
import { getColumKey } from '../pub'
import PubSub from 'pubsub-js';
class ListCon extends React.Component {
    state = {
        dataList: [],
        title: '',
        dataTotal: 0,
        nowIndex: 1,
        termId: 0,
        typeId: 0,
    }
    componentDidMount() {
        const { search } = this.props.location
        const { termId, typeId } = qs.parse(search)
        this.setState({ 'termId': termId, 'typeId': typeId }, () => {
            this.getData()
        })

        this.pubData = PubSub.subscribe('termId', (key, value) => {
            console.log(key)
            console.log(value)
            this.setState({ termId: value[0] == '458' ? '560' : value[0], typeId: value[1], nowIndex: 1 }, () => {
                this.getData()
            })
        })
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.pubData)
    }
    turnData = (val) => {
        PubSub.publish('termId', [val, 1])
    }
    getData = () => {
        const { nowIndex, termId } = this.state
        React.$request({
            url: 'Portal/zt/getListsByTermId',
            method: 'get',
            params: {
                termId: termId,
                page: nowIndex,
                limit: 6,
                order: 'r.listorder asc, istop desc, post_date DESC'
            }
        }).then(res => {
            this.setState({ dataList: res.data.lists, dataTotal: res.data.totalPage * 6 })
        })
    }
    itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <p className='turnBtn'>&lt;</p>;
        }
        if (type === 'next') {
            return <p className='turnBtn'>&gt;</p>;
        }
        return originalElement;
    }
    pageChange = (page, pageSize) => {
        this.setState({ nowIndex: page }, () => {
            this.getData()
        })
    }
    render() {
        const { dataList, dataTotal, nowIndex, termId, typeId } = this.state

        return (
            <div className={styles.listCon}>
                <p className='columTitle'>&nbsp;&nbsp;{getColumKey()[termId]}</p>
                {
                    dataList.map((item, i) => {
                        return typeId === '3' ?
                            <JcspItem {...item} termId={termId} dindex={i} key={item.id} /> : typeId === '2' ?
                                <JchdItem {...item} termId={termId} dindex={i} key={item.id} /> :
                                <GjjlItem {...item} termId={termId} key={item.id} />
                    })
                }
                <div className='paginationBox'>
                    <Pagination onChange={this.pageChange} current={nowIndex} defaultPageSize={6} total={dataTotal} showQuickJumper itemRender={this.itemRender} />
                </div>
            </div>
        )
    }
}
export default withRouter(ListCon)

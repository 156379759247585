import React from 'react'
import styles from './index.module.scss'
import TopSwiper from './components/topSwiper'
import NewsCenter from './components/newsCenter'
import VideoSelect from './components/videoSelect'
import HonouredGuest from './components/honouredGuest'
import SellMetting from './components/sellMetting'
import Jcsj from './components/jcsj'
import Jgfc from './components/jgfc'
import PreviousReview from './components/previousReview'
import Crumbs from './list-components/crumbs'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import {RightOutlined,LeftOutlined} from '@ant-design/icons';

class CuratorSession2024 extends React.Component {
  state = {
    lmtjData: null,
    xwzxData: null,
    gfspData: null,
    fyjbData: null,
    tjhData: null,
    jcsjData: null,
    jgfcData: null,
    wjhgData: null,
    lmtjData: null,
    showCon: false,
    diaImg: [],
    isDiaOpen: false,
    currentDiaImgSet: '',
    isModalOpen: false,
    currentImage: '',
  }
  componentDidMount() {
    this.getDataList()
    this.initSwiper();
  }
  initSwiper = () => {
    new Swiper('.swiper-dia', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      navigation: {
          prevEl: '.turnBtn-l',
          nextEl: '.turnBtn-R',
      },
      // 其他配置...
    });
  };
  closeDia=()=>{
    this.setState({ isDiaOpen: false });
    document.body.style.overflow='auto'
    document.body.style.width='100%'
  }
  openDia=(val)=>{
    let newDiaImg;
    if(val=='hyrc'){
      newDiaImg = [
        '/img/curatorSession/hyrc-2024.jpg',
        '/img/curatorSession/会议日程-高端对话会1.jpg',
        '/img/curatorSession/会议日程-高端对话会2.jpg',
        '/img/curatorSession/会议日程-工作坊1.jpg',
        '/img/curatorSession/会议日程-工作坊2.jpg',
        '/img/curatorSession/会议日程-国际专家讲座.jpg',
        '/img/curatorSession/会议日程-圆桌会议.jpg',
        '/img/curatorSession/会议日程-主旨报告.jpg'
      ]
    }else{
      newDiaImg = ['/img/curatorSession/zzjg-2024.jpg']
    }
    this.setState({ diaImg: newDiaImg, currentDiaImgSet: val, isDiaOpen: true }, () => {
      // 重新初始化轮播图，以更新图片
      this.initSwiper();
    });
    document.body.style.overflow='hidden'
    document.body.style.width='calc(100% - 17px)'
  }
  getDataList = () => {
    React.$request({
      url: 'Portal/zt/termArticles',
      method: 'get',
      params: {
        termId:1063,
        order: "r.listorder asc, post_date DESC"
      }
    }).then(res => {
      res.data.son.forEach(el => {
        console.log(el)
        console.log(typeof el.term_id)
        switch (el.term_id.toString()) {
          case '1064':
            this.setState({ 'xwzxData': el })
            break;
          case '1069':
            this.setState({ 'gfspData': el })
            break;
          case '1070':
            this.setState({ 'fyjbData': el })
            break
          case '1071':
            this.setState({ 'tjhData': el })
            break;
          case '1072':
            this.setState({ 'wjhgData': el })
            break;
          case '1073':
            this.setState({ 'jcsjData': el })
            break;
          case '1074':
            this.setState({ 'jgfcData': el })
            break;
          case '1075':
            this.setState({ 'lmtjData': el })
            break;
          default:
            break;
        }
      })
      this.setState({ showCon: true })
    })
  }
  checkTurn=(val)=>{
    val=='L'?this.topCarousel.prev():this.topCarousel.next()
  }

  openModal = (imageSrc) => {
    this.setState({ isModalOpen: true, currentImage: imageSrc });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { xwzxData, diaImg, isDiaOpen, showCon, wjhgData, fyjbData, tjhData, lmtjData, gfspData, jcsjData, jgfcData, isModalOpen, currentImage } = this.state
    return (
      <>
        {
          showCon ?
            <div className={styles.curatorSession}>
              <Crumbs currentPos={'index'} />
              <TopSwiper lmtjData={lmtjData} />
              <NewsCenter xwzxData={xwzxData} />
              <VideoSelect gfspData={gfspData} />
              <HonouredGuest fyjbData={fyjbData} />
              <SellMetting tjhData={tjhData} />
              <Jcsj jcsjData={jcsjData} />
              <Jgfc jgfcData={jgfcData} />
              <PreviousReview wjhgData={wjhgData} />
            </div> : null
        }
        {
          isDiaOpen ?
          <div className={styles.diaBox}>
            <div className="swiperBox">
              <div className='turnBtn turnBtn-l' ><LeftOutlined /></div>
              <div className="swiper-container swiper-dia">
                  <div className="swiper-wrapper">
                      {
                          diaImg.map((el, index)=>{
                              return(
                                  <div className='swiper-slide' key={index} onClick={() => this.openModal(el)}>
                                      <img src={el} className='tst' alt="" />
                                  </div>
                              )
                          })
                      }
                  </div>
              </div>
              <div className='turnBtn turnBtn-R'><RightOutlined/></div>
            </div>
            <img src="/img/curatorSession/close.png" className='close' alt="" onClick={()=>this.closeDia()}/>
          </div>
          : null
        }
        {isModalOpen && (
          <div className={styles.modal} onClick={this.closeModal}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
              <img src={currentImage} alt="" className={styles.modalImage} />
              {/* <button onClick={this.closeModal}>关闭</button> */}
            </div>
          </div>
        )}
        <img src="https://www.bjsc.net.cn/administrator/data/upload/portal/resource/img/curatorSession/hyrcBtn.png" alt="" onClick={()=>this.openDia('hyrc')} className={styles.floatImg} />
        <img src="https://www.bjsc.net.cn/administrator/data/upload/portal/resource/img/curatorSession/zzjgBtn.png" alt="" onClick={()=>this.openDia('zzjg')} className={styles.floatImg1} />
      </>
    )
  }
}
export default CuratorSession2024

export function changeImg(val){
    if (!val) {
        return ''
    }
    if(val.indexOf('www.bjsc.net.cn')!=-1){
        return val
    }else{
        return `${process.env.REACT_APP_IMGBASE}` + val
    }
}

export function getColumKey(){
    return {
        560:'新闻中心',
        458:'新闻中心',
        459:'新闻中心',
        460:'新闻中心',
        461:'新闻中心',
        462:'官方视频',
        463:'发言嘉宾',
        464:'推荐会',
        465:'媒体报道',
        466:'栏目推荐'      
    }
}
import styled from 'styled-components'

export const CuratorSessionAllWrap = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .banner-img {
            width: 1200px;
            margin: 30px auto 30px;
            display: flex;  
            justify-content: space-between; /* 如果需要均匀分布图片，可以使用这个属性 */  
            align-items: center; /* 如果需要垂直居中图片，可以使用这个属性 */  
            .banner-img-link {  
                width: 591px;  
                height: 388px;  
                display: inline-block; /* 使得每个链接都表现为内联块级元素 */  
                text-align: center; /* 保证图片在链接中居中 */  
                position: relative; /* 确保图片标题在图片下方 */
                // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                .banner-img-item {  
                    width: 100%;
                    height: 100%;
                    display: block; /* 将图片作为块级元素显示 */  
                    margin-right: 38px; /* 为每个图片添加右边距 */  
                } 
                .banner-img-caption {  
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 5px;
                    background-color: #00a1b4;
                    color: white;
                    text-align: center;
                    font-size: 25px;
                    line-height: 1.2;
                    box-sizing: border-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                } 
            } 
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .banner-img {
            width: 100%; /* 使用百分比或视口单位vw，以确保宽度适应屏幕 */  
            max-width: 7.1rem; /* 设置最大宽度，防止过大 */  
            margin: 0.3rem auto 0.3rem;  
            display: flex;  
            flex-wrap: wrap; /* 允许图片在必要时换行 */  
            justify-content: space-between; /* 均匀分布图片，如果需要的话 */
            .banner-img-link {  
                flex: 0 0 calc(50% - 0.1rem); /* 每个链接占据50%宽度减去一半间距 */  
                box-sizing: border-box; /* 包含padding和border在元素的总宽度和总高度中 */  
                position: relative;
                .banner-img-item {  
                    width: 100%; /* 图片宽度设置为100%，以填充链接容器 */  
                    height: 100%; /* 高度自动，以保持图片的原始比例 */  
                    margin-right: 0.38rem; /* 设置图片之间的间隔 */  
                } 
                .banner-img-caption {  
                    position: absolute; /* 确保标题在图片下方 */
                    bottom: 0;  
                    left: 0;  
                    width: 100%;  
                    padding: 0.03rem;  
                    background-color: #00a1b4 
                    color: white; /* 标题颜色 */  
                    text-align: center; /* 标题居中 */  
                    font-size: 0.09rem; /* 标题字体大小 */  
                    box-sizing: border-box; /* 确保 padding 不会增加元素的总宽度 */  
                    overflow: hidden; 
                    text-overflow: ellipsis; 
                    white-space: nowrap; 
                    line-height: 1.2; /* 标题行高 */
                } 
            }  
        }
    }

`
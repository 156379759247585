import React from 'react'
import {LeftOutlined,RightOutlined} from '@ant-design/icons';
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import styles from '../index.module.scss'
import { changeImg } from '../pub';
import { Link ,withRouter} from 'react-router-dom';

class Jcsj extends React.Component {
    componentDidMount() {
        new Swiper('.swiper-jcsj', {
            pagination: '.swiper-pagination',
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 20,
            loop: true,
            navigation: {
                prevEl: '.turnBtn-l',
                nextEl: '.turnBtn-R',
            },
        })
        new Swiper('.swiper-jcsj-yd', {
            pagination: '.swiper-pagination',
            slidesPerView: "auto",
            spaceBetween: 10,
            navigation: {
                prevEl: '.turnBtn-l',
                nextEl: '.turnBtn-R',
            },
        })
    }
    turn(id,termId){
        this.props.history.push(`/curatorSession2024Con?articleId=${id}&termId=${termId}`)
    }
  render() {
    const {jcsjData} = this.props
    return (
        <div className={styles.jcsj}>
            <p className='title'>{jcsjData.name}
                <Link className='link' to={`/curatorSession2024List?termId=${jcsjData.term_id}&typeId=${jcsjData.post_keywords}`}>
                    更多
                </Link>
            </p>
            <div className="swiperBox">
                <div className="swiper-container swiper-jcsj">
                    <div className="swiper-wrapper">
                        {
                            jcsjData.articles.map(el=>{
                                return(

                                    <div className='swiper-slide' key={el.id} onClick={()=>this.turn(el.id,el.term_id)}>
                                        <img src={changeImg(el.smeta.photo[0].url)} alt="" />
                                        <p className="text">{el.post_title}</p>
                                    </div>
                                  
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="swiperBox-yd">
                <div className="swiper-container-yd swiper-jcsj-yd">
                    <div className="swiper-wrapper">
                        {
                            jcsjData.articles.map(el=>{
                                return(
                                    
                                    <div className='swiper-slide' key={el.id} onClick={()=>this.turn(el.id,el.term_id)}>
                                        <img src={changeImg(el.smeta.photo[0].url)} alt="" />
                                        <p className="text">{el.post_title}</p>
                                    </div>
                                    
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
export default withRouter(Jcsj)

import React from 'react'
import styles from './index.module.scss'
import TopSwiper from './components/topSwiper'
import NewsCenter from './components/newsCenter'
import VideoSelect from './components/videoSelect'
import HonouredGuest from './components/honouredGuest'
import SellMetting from './components/sellMetting'
import PreviousReview from './components/previousReview'
import Crumbs from './list-components/crumbs'
class CuratorSession extends React.Component {
  state = {
    lmtjData: null,
    xwzxData: null,
    gfspData: null,
    fyjbData: null,
    fyjb2024ata: null,
    tjhData: null,
    wjhgData: null,
    lmtjData: null,
    showCon: false,
    diaImg:''
  }
  componentDidMount() {
    this.getDataList()
  }
  closeDia=()=>{
    this.setState({'diaImg':''})
    document.body.style.overflow='auto'
    document.body.style.width='100%'
  }
  openDia=(val)=>{
    if(val=='hyrc'){
      this.setState({'diaImg':'/img/curatorSession/hyrc.jpg'})
    }else{
      this.setState({'diaImg':'/img/curatorSession/zzjg.jpg'})
    }
    document.body.style.overflow='hidden'
    document.body.style.width='calc(100% - 17px)'
  }
  getDataList = () => {
    React.$request({
      url: 'Portal/zt/termArticles',
      method: 'get',
      params: {
        termId: 457
      }
    }).then(res => {
      res.data.son.forEach(el => {
        console.log(el)
        console.log(typeof el.term_id)
        switch (el.term_id.toString()) {
          case '458':
            this.setState({ 'xwzxData': el })
            break;
          case '462':
            this.setState({ 'gfspData': el })
            break;
          case '463':
            this.setState({ 'fyjbData': el })
            break
          case '464':
            this.setState({ 'tjhData': el })
            break;
          case '465':
            this.setState({ 'wjhgData': el })
            break;
          case '466':
            this.setState({ 'lmtjData': el })
            break;
          default:
            break;
        }
      })
      this.setState({ showCon: true })
    })
  }
  render() {
    const { xwzxData, diaImg,showCon, wjhgData, fyjbData, tjhData, lmtjData, gfspData } = this.state
    return (
      <>
        {
          showCon ?
            <div className={styles.curatorSession}>
              <Crumbs currentPos={'index'} />
              <TopSwiper lmtjData={lmtjData} />
              <NewsCenter xwzxData={xwzxData} />
              <VideoSelect gfspData={gfspData} />
              <HonouredGuest fyjbData={fyjbData} />
              <SellMetting tjhData={tjhData} />
              <PreviousReview wjhgData={wjhgData} />
            </div> : null
        }
        {
          diaImg!=''?
            <div className={styles.diaBox}>
              <img src={diaImg} className='tst' alt="" />
              <img src="/img/curatorSession/close.png" className='close' alt="" onClick={()=>this.closeDia()}/>
            </div>:null
        }
        <img src="https://www.bjsc.net.cn/administrator/data/upload/portal/resource/img/curatorSession/hyrcBtn.png" alt="" onClick={()=>this.openDia('hyrc')} className={styles.floatImg} />
        <img src="https://www.bjsc.net.cn/administrator/data/upload/portal/resource/img/curatorSession/zzjgBtn.png" alt="" onClick={()=>this.openDia('zzjg')} className={styles.floatImg1} />
      </>
    )
  }
}
export default CuratorSession

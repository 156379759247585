import React from 'react'
import styles from '../list.module.scss'
import { ClockCircleOutlined, SwapRightOutlined } from '@ant-design/icons';
import { changeImg } from '../pub';
import { withRouter } from 'react-router-dom';
class JcspItem extends React.Component {
  turn(id, termId) {
    this.props.history.push(`/curatorSession2024Con?articleId=${id}&termId=${termId}`)
  }
  render() {
    const { post_title, post_excerpt, post_date, smeta, dindex, termId, id } = this.props
    return (
      <>
        <div className={styles.jcspItem} style={{ marginRight: (dindex) % 3 + 1 == 3 ? '0px' : '22px' }} onClick={() => this.turn(id, termId)}>
          <img src={smeta.photo ? changeImg(smeta.photo[0].url) : `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/zwtp.jpg`} alt="" />
          <div className='conBox'>
            {/* <p className='time'>{post_date.split(' ')[0]}</p> */}
            <p className="title">{post_title}</p>
            <p>{post_excerpt}</p>
          </div>
        </div>
        <div className={styles.jcspItemYd} style={{ marginRight: (dindex) % 2 + 1 == 2 ? '0px' : '.22rem' }} onClick={() => this.turn(id, termId)}>
          <img src={smeta.photo ? changeImg(smeta.photo[0].url) : `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/zwtp.jpg`} alt="" />
          <div className='conBox'>
            {/* <p className='time'>{post_date.split(' ')[0]}</p> */}
            <p className="title">{post_title}</p>
            <p>{post_excerpt}</p>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(JcspItem)

